$(document).ready(function(){
    /* Masks */
    $("#phone").mask("(99) 9999-9999");
    $("#mobile_phone").mask("(99) 99999-999?9");
    
    /* Scroll */
    $('#navegacao ul li a').click(function(e){
        e.preventDefault();
        scroller($(this).data("target"));
    })
    $('.btn-main').click(function(e){
        e.preventDefault();
        scroller("contato");
    })
    /*Check user agent */
    function setWhatsUrl() { 
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            $('.whatslink').each(function(i,e){
                e.href = e.href.replace("web.whatsapp", "api.whatsapp");
            });
        }
    }
    setWhatsUrl();

    $('#enviar-form').click(function(){
        $(this).prop('disabled', true);
        $('#form-contato').submit();
    });
})


function scroller(target) {
    $('html, body').animate({ scrollTop: $('#' + target).offset().top }, 'slow');
}